import { Link } from "gatsby"
import React, { Component } from 'react'
import loadable from '@loadable/component'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));
const EngMarketplacePage = loadable(() => import("../components/MarketplacePage/english.js"));
const ArabicMarketplacePage = loadable(() => import("../components/MarketplacePage/arabic.js"));
const ChineseMarketplacePage = loadable(() => import("../components/MarketplacePage/chinese.js"));

const schema = {
  "@context": "http://schema.org/",
  "@graph": [
    {
      "@type": "product",
      "image": "https://softtik.com/images/nft-collection/invest-section-2.webp",
      "name": "The Leading NFT Marketplace Development Company",
      "description": "Softtik, the leading NFT Marketplace Development Company, provides high-end services & solutions to its clients using the latest cutting-edge technologies.",
      "brand": "Softtik Technologies",
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.8",
        "reviewCount": "135"
      }
    },
    {
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "What is an NFT Marketplace?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>An <b>NFT Marketplace</b> is an online marketplace where non-fungible art is sold and purchasers can purchase it using cryptocurrency such as Bitcoin. Most marketplaces charge a transaction fee and demand that you create an account with them (usually a free one).</p>"
        }
      }, {
        "@type": "Question",
        "name": "How is an NFT Marketplace developed?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>The NFT markets are built using smart contracts to monitor the decentralized platform and make it run as efficiently as possible. The majority of current NFT marketplaces are based on the blockchain networks listed below. Each blockchain has its unique set of features that are reflected in the <b>NFT marketplace</b>.</p>"
        }
      }, {
        "@type": "Question",
        "name": "How much does it cost to develop an NFT Marketplace?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>The cost of developing an <b>NFT marketplace</b> changes depending on your requirements and features. A simple marketplace with low features will be less costly as compared to a complex one.</p>"
        }
      }
      ]
    }
  ]
}

export class MarketplacePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      lang: "English"
    }
  };


  async componentDidMount() {
    let region = localStorage.getItem('region');

    if (region == 'ae') this.setState({ lang: "Arabic" });
    else if (region == 'cn') this.setState({ lang: "China" });
    else this.setState({ lang: "English" });
  };

  render() {
    let { lang } = this.state;

    return (
      <>
        <InnerLayout header='Main' fallback={<Loader />}>
          <Head
            type="Website"
            fallback={<Loader />}
            schemaMarkup={schema}
            path="/nft-marketplace-development-services/"
            title="NFT Marketplace Development Company - Softtik Technologies"
            thumbnail="https://softtik.com/images/nft-collection/invest-section-2.webp"
            description="Softtik, the leading NFT Marketplace Development Company, provides high-end services & solutions to its clients using the latest cutting-edge technologies."
          />
          <main>
            <div id="wrapper">
              <div className={`collection-page game-development-page ${lang == "Arabic" && 'rtl'}`}>
                {lang == "Arabic"
                  ? <ArabicMarketplacePage />
                  : lang == "China"
                    ? <ChineseMarketplacePage />
                    : <EngMarketplacePage />
                }
              </div>
            </div>
          </main>
        </InnerLayout >
      </>
    )
  };
};

export default MarketplacePage;